<template>
	<div class="myPage">
		<div id="bce-content">
			<div class="header ui-v5 loaded" id="header">

				<a class="logo zh-logo" target="_blank">
					<img src="../assets/logo.png" />
				</a>
				<div class="content">
					<div class="header-left">


					</div>
					<div class="header-right">

						<div class="user-info">

							<div id="user-nav">
								<div class="iam-antd-global iam-header-user-info_LMC_ZA0">
									<div class="base-info_qS_guBw">
										<div class="avatar_l4eGGBq">
											<img :src="headImg" />
										</div>
										<div>
											<div class="displayName_YOBl6dV"><span
													class="iam-antd-typography iam-antd-typography-ellipsis iam-antd-typography-single-line iam-antd-typography-ellipsis-single-line">{{userName}}</span>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="main-area ui-v5 ui-hack-v1">
				<div id="main" class="ui-v5"></div>
				<div id="react-main">
					<div class="antd-container">
						<div class="pageArea">
							<div class="idaas-right-layout-wrap idaas-right-layout-wrap-card-list">
								<div class="idaas-right-layout-header">账号管理</div>
								<div class="idaas-right-layout-content-wrap">
									<div class="idaas-right-layout-content">
										<div class="idaasCard_wrap  only-show card-list  ">
											<div class="idaasCard_header">
												<div class="idaasCard_title">基本信息</div>
											</div>
											<div class="idaasCard_body ">
												<div class="idaas-ant-spin-nested-loading">
													<div class="idaas-ant-spin-container">
														<div class="main___rxKhicV">
															<div class="left___2zRBC_t">
																<div class="avatar___10-2Ajn">
																	<img :src="headImg" />
																</div>
																<div>
																	<div class="title___2WYUp1L">{{userName}}</div>
																	<div class="subTitle___35JVUwv">
																		账号ID: 
																		<span class="idaas-ant-typography">
																			{{sn}}
																		</span>
																	</div>
																</div>
															</div>
															<div class="right___2lGWEHm">
																<div class="row___1KfURR7">
																	<div class="col___GZ2UC7v">
																		<div class="label___1R_LyuX">绑定手机</div>
																		<div class="content___2LyHrjo">{{phone}}</div>
																	</div>
																	<!-- <div class="col___GZ2UC7v">
																		<div class="label___1R_LyuX">属性</div>
																		<div class="content___2LyHrjo">个人</div>
																	</div> -->
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="idaasCard_wrap  only-show card-list  " style="min-height: 240px;">
											<div class="idaasCard_header">
												<div class="idaasCard_title"></div>
											</div>
											<div class="idaasCard_body  no-title">
												<div class="idaas-ant-spin-nested-loading">
													<div class="idaas-ant-spin-container">
														<div id="console-qualify-sdk-part">
															<div class="css-1mngama ant-app css-var-r0">
																<div>
																	<div class="ant-space css-1mngama ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small css-var-r0"
																		style="width: 100%; justify-content: space-between;">
																		<div class="ant-space-item">
																			<div
																				class="ant-space css-1mngama ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small css-var-r0">
																				<div class="ant-space-item">
																					<div class="sc-bCvmQg bJMVmw">官媒证书</div>
																				</div>
																				<!-- <div class="ant-space-item"><span
																						class="ant-tag ant-tag-success css-1mngama css-var-r0"
																						style="border-radius: 2px;">个人认证已完成</span>
																				</div> -->
																			</div>
																		</div>
																	</div>
																	<div class="sc-iLXxbI cfzwDL">
																		<div class="noData">
																			还没有官媒证书哦，去注册一个吧~~
																		</div>

																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>


										<div class="idaasCard_wrap  only-show card-list  ">
											<div class="idaasCard_header">
												<div class="idaasCard_title">账号退出</div>
											</div>
											<div class="idaasCard_body ">
												<div class="idaas-ant-spin-nested-loading">
													<div class="idaas-ant-spin-container">
														您可以在此退出当前中文·官媒账号。账号退出成功后，将跳转至首页，重新登录后可再次进入当前页<button
															type="button"
															class="idaas-ant-btn idaas-ant-btn-primary idaas-ant-btn-dangerous"
															style="height: 26px; padding: 2px 12px; position: absolute; top: -18px; right: 16px;">
															<span @click="tc">退出</span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { message } from 'ant-design-vue'
	export default {
		data() {
			return {
				headImg:'',
				userName:'',
				token:'',
				sn:'',
				phone:''
			}
		},
		mounted() {
			if(!localStorage.getItem('token')){
				// message.info('请登录')
				this.$router.push('Index')
			}
			this.getInfo()
			// message.info('12121313131')
		},
		methods:{
			async getInfo(){
				
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/user/center",
					headers:{
						token:localStorage.getItem('token')
					},
					data: {}
				})
				console.log(res)
				if(res.data.code==1){
					this.headImg=res.data.data.avatar
					this.userName=res.data.data.nickname
					this.sn=res.data.data.sn
					this.phone=res.data.data.mobile
				}
			},
			tc(){
				let that = this
				console.log(123)
				localStorage.clear()
				message.success('退出成功')
				setTimeout(function(){
					that.$router.push('/Index')
				},1500)
				
			}
		}
	}
</script>

<style scoped>
	@import url('../style/my.css');
	.noData
	{
		display: block;
		text-align: center;
		padding: 40px 0;
	}
</style>